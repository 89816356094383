<template>
  <div>
    <ProducerCard
      :chart="res_data"
      :chart_max_val="max"
      name="ассортимент"
      :rating="res_data.length ? res_data[res_data.length - 1].position : 0" 
    >
      <template v-slot:content>
        <div style="width: 100%">
          <v-progress-linear v-if="res_data.length"
            color="background5"
            class="rounded-0"
            v-model="res_data[res_data.length - 1].share"
            height="25"
          >
            <div class="background2--text">
              {{ res_data.length ? Math.ceil(res_data[res_data.length - 1].share) : 0 }}%
            </div>
          </v-progress-linear>
          <div
            class="text-10 grey3--text mr-2 text-right"
            v-if="res_data.length"
          >
            {{ res_data[res_data.length - 1].count }} из
            {{ res_data[res_data.length - 1].all }}
          </div>
        </div>
      </template>
    </ProducerCard>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProducerCard from "./ProducerCardComponent";
import { back_request } from "../../mixins/back";

// import axios from "axios";
export default {
  name: "Producer",
  mixins: [back_request],
  components: {
    ProducerCard,
  },
  data: () => ({
    res_data: [],
  }),
  computed: {
    ...mapState([
      "user",
      "months",
      "month",
      "year",
      "back_domen",
      "shop",
      "access",
      "months",
    ]),
    max() {
      return Math.max(...this.res_data.map((el) => el.share), 0);
    },
  },
  methods: {
    get_data() {
      let finishdate = new Date();
      let startdate = new Date(
        finishdate.getFullYear(),
        finishdate.getMonth(),
        1,
        3
      );
      let producer = Object.entries(this.access).reduce(
        (acc, [k, v]) => (v == "producer" ? [...acc, k] : acc),
        []
      );
      if (!producer.length) return;
      producer = producer[0];
      for (let i = 0; i < 3; i++) {
        startdate = new Date(
          startdate.getFullYear(),
          startdate.getMonth() - 1,
          1,
          3
        );
        finishdate = new Date(
          startdate.getFullYear(),
          startdate.getMonth() + 1,
          1,
          3
        );
        let key = startdate;
        this.get_producer_data(
          "articles",
          producer,
          startdate,
          finishdate
        ).then(
          (res) => res.name ? 
            (this.res_data = [
              ...this.res_data,
              { share: 0,
                ...res,
                date: key,
                month: this.months[key.getMonth()].slice(0, 3),
              },
            ].sort((a, b) => a.date - b.date)) : ''
        );
      }
    },
  },
  watch: {
    // res_data () {
    //   console.log(this.res_data);
    // }
  },
  created() {
    this.get_data();
  },
};
</script>

<style scoped></style>
