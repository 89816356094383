<template>
  <div>
    <div class="text-center text-h6 my-3">
      ТОП {{ months[new Date().getMonth() - 1] }}
    </div>
    <div class="d-flex flex-wrap">
      <div v-for="el in res_data" :key="el.name" class="mr-8 mb-2">
        <v-badge bordered color="background6" :content="el.count" overlap>
          <v-card flat class="pa-2 px-3 rounded-lg background7">
            <span class="first2--text">
              {{ el.collection }}
            </span>
            <span>
              {{ el.name }}
            </span>
          </v-card>
        </v-badge>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { back_request } from "../../mixins/back";

// import axios from "axios";
export default {
  name: "Producer",
  mixins: [back_request],
  components: {},
  data: () => ({
    res_data: [],
  }),
  computed: {
    ...mapState([
      "user",
      "months",
      "month",
      "year",
      "back_domen",
      "shop",
      "access",
      "months",
    ]),
    max() {
      return Math.max(...this.res_data.map((el) => el.share), 0);
    },
  },
  methods: {
    get_data() {
      let finishdate = new Date();
      let startdate = new Date(
        finishdate.getFullYear(),
        finishdate.getMonth(),
        1,
        3
      );
      let producer = Object.entries(this.access).reduce(
        (acc, [k, v]) => (v == "producer" ? [...acc, k] : acc),
        []
      );
      if (!producer.length) return;
      producer = producer[0];
      for (let i = 0; i < 1; i++) {
        startdate = new Date(
          startdate.getFullYear(),
          startdate.getMonth() - 1,
          1,
          3
        );
        finishdate = new Date(
          startdate.getFullYear(),
          startdate.getMonth() + 1,
          1,
          3
        );
        this.get_producer_data(
          "article_top",
          producer,
          startdate,
          finishdate
        ).then((res) => (this.res_data = res));
      }
    },
  },
  watch: {
    // res_data () {
    //   console.log(this.res_data);
    // }
  },
  created() {
    this.get_data();
  },
};
</script>

<style scoped></style>
