<template>
  <div>
    <v-card
      flat
      class="text-16 d-flex align-end pb-3 mt-10"
      :class="background_color"
      style="border-radius: 14px; position: absolute"
      :width="width"
      :height="height * 1.13"
    >
      <slot name="content" />
    </v-card>
    <v-card
      elevation="10"
      class="background7 text-16"
      style="border-radius: 14px"
      :width="width"
      :height="height"
    >
      <div>
        <div class="text-center pt-2">{{ name }}</div>
        <div v-if="chart.length">
          <div class="text-center text-96 first2--text mt-n3 py-0">
            {{ chart[chart.length - 1].position }}
          </div>
          <div class="text-center mt-n9 first2--text" style="opacity: 0.5">
            место
          </div>
        </div>
        <div>
          <v-sparkline
            :value="chart.map((el) => el.share || el.result)"
            color="background5"
            height="100"
            padding="0"
            stroke-linecap="round"
            smooth
          >
          </v-sparkline>
          <div
            v-if="chart.length > 1"
            class="d-flex justify-space-between text-8 px-3 error2--text mt-n1"
          >
            <div v-for="el in chart" :key="el.month">
              {{ el.month }}
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "ProducerCardComponent",

  components: {},
  props: {
    background_color: {
      type: String,
      required: false,
      default: "background7",
    },
    height: {
      type: Number,
      required: false,
      default: 226,
    },
    width: {
      type: Number,
      required: false,
      default: 162,
    },
    name: {
      type: String,
      required: false,
    },
    chart: {
      required: false,
    },
    rating: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data: () => ({
    month: [],
  }),
  computed: {},
  methods: {},
  watch: {},
  created() {},
};
</script>

<style scoped></style>
