<template>
  <div>
    <ProducerCard
      :chart="res_data"
      name="оборачиваемость"
      :rating="res_data.length ? res_data[res_data.length - 1].position : 0"
    >
      <template v-slot:content>
        <div style="width: 100%" class="d-flex" v-if="res_data.length">
          <div class="mx-auto text-36 background3--text">
            {{ res_data[res_data.length - 1].result.toFixed() }}дн
          </div>
        </div>
      </template>
    </ProducerCard>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ProducerCard from "./ProducerCardComponent";
import { back_request } from "../../mixins/back";

// import axios from "axios";
export default {
  name: "Producer",
  mixins: [back_request],
  components: {
    ProducerCard,
  },
  data: () => ({
    res_data: [],
  }),
  computed: {
    ...mapState([
      "user",
      "months",
      "month",
      "year",
      "back_domen",
      "shop",
      "access",
      "months",
    ]),
  },
  methods: {
    get_data() {
      let finishdate = new Date();
      let startdate = new Date(
        finishdate.getFullYear(),
        finishdate.getMonth(),
        1,
        3
      );
      let producer = Object.entries(this.access).reduce(
        (acc, [k, v]) => (v == "producer" ? [...acc, k] : acc),
        []
      );
      if (!producer.length) return;
      producer = producer[0];
      for (let i = 0; i < 3; i++) {
        startdate = new Date(
          startdate.getFullYear(),
          startdate.getMonth() - 1,
          1,
          3
        );
        finishdate = new Date(
          startdate.getFullYear(),
          startdate.getMonth() + 1,
          1,
          3
        );
        let key = startdate;
        this.get_producer_data(
          "turnover_ratio_day",
          producer,
          startdate,
          finishdate
        ).then((res) =>
          res.name
            ? (this.res_data = [
                ...this.res_data,
                {
                  ...res,
                  date: key,
                  month: this.months[key.getMonth()].slice(0, 3),
                },
              ].sort((a, b) => a.date - b.date))
            : ""
        );
      }
    },
  },
  watch: {
    // res_data () {
    //   console.log(this.res_data);
    // }
  },
  created() {
    this.get_data();
  },
};
</script>

<style scoped></style>
