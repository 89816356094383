<template>
  <v-card
    flat
    max-width="1900"
    class="mb-16"
    :class="!$vuetify.breakpoint.xs ? 'pa-10' : 'mx-auto'"
  >
    <v-row>
      <v-col cols="12" lg="10" class="d-flex flex-wrap">
        <Assortiment class="card" />
        <Sell class="card" />
        <Marginality class="card" />
        <Turnover class="card" />
        <TopProduct />
      </v-col>
      <v-col cols="12" lg="2">
        <Returns />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Assortiment from "./ProducerAssortimentComponent";
import Marginality from "./ProducerMarginalityComponent";
import Returns from "./ProducerReturnsComponent";
import Sell from "./ProducerSellComponent";
import Turnover from "./ProducerTurnoverComponent";
import TopProduct from "./ProducerTopProductComponent";

// import axios from "axios";
export default {
  name: "Producer",

  components: {
    Assortiment,
    Marginality,
    Returns,
    Sell,
    Turnover,
    TopProduct,
  },
  data: () => ({}),
  computed: {},
  methods: {},
  watch: {},
  created() {},
};
</script>

<style scoped>
.card {
  margin-bottom: 100px;
  margin-right: 60px;
}
</style>
